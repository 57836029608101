
import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { format, getPayloadObject, getTelemetryAttributes, ITelemetryContent, onTelemetryClick } from '@msdyn365-commerce-modules/utilities';
import { IComponentProps, ICoreContext, IGridSettings, IImageData, IImageSettings, Image } from '@msdyn365-commerce/core';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import React from 'react';

export interface IProductComponentProps {
    allowBack?: boolean;
    context: ICoreContext;
    imageSettings?: IImageSettings;
    ratingAriaLabel?: string;
    telemetryContent?: ITelemetryContent;
}

const ProductCard: React.FC<IProductComponentProps & IComponentProps<{product?: ProductSearchResult}>> = props => {
    // Props
    const {
        allowBack,
        context,
        data,
        imageSettings,
        ratingAriaLabel,
        telemetryContent,
    }                   = props;
    const product       = props.data.product;
    if (!product) {
        return null;
     }
    let productUrl      = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, undefined);
    if (allowBack) {
        productUrl      = updateProductUrl(productUrl, context);
    }
    // Data
    const payLoad       = getPayloadObject('click', telemetryContent!, '', product.RecordId.toString());
    const attribute     = getTelemetryAttributes(telemetryContent!, payLoad);
    const basePrice     = product.BasePrice?.toLocaleString('it-IT');
    const adjustedPrice = product.Price?.toLocaleString('it-IT');
    // const discountValue  = discount !== undefined ? discount[0].OfferName : '';
    const duration      = product.ExtensionProperties?.filter(i => i.Key === 'NumOfMonthSantander')[0]?.Value?.IntegerValue;
    const tan           = product.ExtensionProperties?.filter(i => i.Key === 'TanSantander')[0]?.Value?.DecimalValue;
    const taeg          = product.ExtensionProperties?.filter(i => i.Key === 'TaegSantander')[0]?.Value?.DecimalValue;
    const fromAmountSA  = product.ExtensionProperties?.filter(i => i.Key === 'FromAmountSantander')[0].Value?.DecimalValue;
    const legalNotice   = product.ExtensionProperties?.filter(i => i.Key === 'LegalNotice') ? product.ExtensionProperties?.filter(i => i.Key === 'LegalNotice')[0].Value?.StringValue : '';
    return (
        <a href={productUrl} onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)} aria-label={renderLabel(product.Name, context.cultureFormatter.formatCurrency(product.Price), product.AverageRating, ratingAriaLabel)} className='msc-product' {...attribute}>
            <div className='msc-product__image'>
                {renderProductPlacementImage(imageSettings, context.request.gridSettings,product.PrimaryImageUrl, product.Name)}
            </div>
            <div className={'msc-product__details'}>
                <h4 className={'msc-product__title'}>{product.Name}</h4>
                {renderAttributes(data)}
                <div>
                    <div>
                        <span className={'pre-price__actual'}>A partire da</span>
                        <span className={'msc-price__actual'}>{adjustedPrice}€*</span>
                    </div>
                    <div className={'price-offer'}>
                        {adjustedPrice !== basePrice && (
                        <div className={'price-original'}>
                            Prezzo di listino <span className='msc-price__strikethrough'>{basePrice}€</span>
                            {/*discountValue*/}
                        </div>
                        )}
                        {props?.context?.app?.config?.enableFinancing && (
                        <div>
                            oppure
                            <span className={'financing-val'}> {fromAmountSA?.toLocaleString('it-IT')}€/MESE </span>
                            per
                            <span className={'offer-duration'}> {duration} MESI</span>
                            <span className={'tan-val'}> - TAN {tan?.toLocaleString('it-IT')}%</span>
                            <span className={'taeg-val'}> - TAEG {taeg?.toLocaleString('it-IT')}%</span>
                            {legalNotice &&
                            <span aria-hidden='true' className={'icon-tooltip icon-tooltip__small'}>
                                <span aria-hidden='true' className={'icon icon-info'}/>
                                <div className='tooltip'>
                                    <p>{legalNotice}</p>
                                </div>
                            </span>
                            }
                        </div>
                        )}
                    </div>
                    <p className='price-infos'>{props?.context?.app?.config?.taxOutOfPriceText}</p>
                </div>
                {renderDescription(product.Description)}
                <button className='msc-btn'>Scopri di più</button>
            </div>
        </a>
    );
};

function renderAttributes(data: {product?: ProductSearchResult | undefined}): JSX.Element | null {
    const attributes: string[] = [];
    data.product?.AttributeValues?.forEach((attribut) => {
        if (['Trazione', 'Alimentazione', 'Trasmissione'].includes(attribut.KeyName as string)) {
           attributes.push(attribut.TextValue as string);
        }
    });

    return (
        <div className='tags-list'>
            {attributes.map((attr,index) => <span key={index} className='tags-item'>{attr}</span>
            )}
        </div>
    );
}

function renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
    name = name || '';
    price = price || '';
    return (`${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`);
}

function renderDescription(description?: string): JSX.Element | null {
    return <p className='msc-product__text'>{description}</p>;
}

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
    if (rating && ratingAriaLabel) {
    const roundedRating = rating.toFixed(2);
    return format(ratingAriaLabel || '', roundedRating, '5');
    }
    return '';
}

function updateProductUrl(productUrl: string, context:ICoreContext): string {
    const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
    const queryString = `back=true` ;
    if (srcUrl.search) {
            srcUrl.search += `&${queryString}`;
        } else {
            srcUrl.search += queryString;
        }

    const updatedUrl = new URL(srcUrl.href);
    return updatedUrl.pathname + srcUrl.search;
}

function renderProductPlacementImage(imageSettings?: IImageSettings, gridSettings?: IGridSettings, imageUrl?: string, altText?: string): JSX.Element | null {
    if (!imageUrl || !gridSettings || !imageSettings) {
        return null;
    }
    const img: IImageData = {
        src: imageUrl,
        altText: altText ? altText : ''
    };
    const imageProps = {
        gridSettings: gridSettings,
        imageSettings: imageSettings
    };
    imageProps.imageSettings.cropFocalRegion = true;
    return (
        <Image {...img} {...imageProps} loadFailureBehavior='empty' />
    );
}

// @ts-ignore
export default ProductCard;